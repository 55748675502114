import { Box } from "@chakra-ui/react";
import Footer from "../../components/Footer";
import FeaturesSection from "./sections/FeaturesSection";
import HeaderSection from "./sections/HeaderSection";

function Landing() {
  return (
    <>
      <Box mb="10rem">
        <HeaderSection />
        <FeaturesSection />
      </Box>
      <Footer />
    </>
  );
}

export default Landing;
