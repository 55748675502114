import { useHistory } from "react-router";
import { Box, Button } from "@chakra-ui/react";

function GradientLinkBtn({
  text,
  href,
  ...props
}:
  | {
      text: string;
      href: string;
    }
  | any) {
  const history = useHistory();

  const routeChange = (newPath: string) => {
    history.push(newPath);
  };

  return (
    <Box>
      <Button
        px={6}
        py={8}
        rounded="1rem"
        bgGradient="linear(to-l, #9600f0,#c900c4,#c900c4,#9600f0)"
        border="4px"
        borderColor="#9600f0"
        bgSize="200%"
        bgPos="left"
        fontWeight="bolder"
        _hover={{
          bgPos: "right",
        }}
        _active={{
          bg: "#d957d6",
        }}
        transition="200ms background-position ease-in-out"
        onClick={() => routeChange(href)}
        {...props}
      >
        {text}
      </Button>
    </Box>
  );
}

export default GradientLinkBtn;
