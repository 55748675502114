import { ReactNode } from "react";
import {
  Box,
  Container,
  Stack,
  useColorModeValue,
  Text,
  Button,
  VisuallyHidden,
  Link,
  Center,
} from "@chakra-ui/react";
import Logo from "./Logo";
import { FaDiscord, FaTwitter, FaGithub } from "react-icons/fa";

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <Button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w="12"
      h="12"
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </Button>
  );
};

function Footer() {
  return (
    <Box minH="12rem" bg="blackAlpha.500" borderTop="2px" borderColor="white">
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Link href="/">
          <Center>
            <Logo h="4rem" w="4rem" rounded="0.5rem" />
          </Center>
          <Text fontWeight="semibold">NFTPool.Exchange</Text>
        </Link>
        <Stack direction={"row"} spacing={6}>
          <SocialButton
            label={"Twitter"}
            href={"https://twitter.com/NFTPoolExchange"}
          >
            <FaTwitter />
          </SocialButton>
          <SocialButton label={"Discord"} href={"#"}>
            <FaDiscord />
          </SocialButton>
          <SocialButton
            label={"Github"}
            href={"https://github.com/NFTPool-Exchange"}
          >
            <FaGithub />
          </SocialButton>
        </Stack>
        {/* <Stack direction={"row"} spacing={6}>
          <Link href={"#"}>Home</Link>
          <Link href={"#"}>About</Link>
          <Link href={"#"}>Blog</Link>
          <Link href={"#"}>Contact</Link>
        </Stack> */}
      </Container>
    </Box>
  );
}

export default Footer;
