import { useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  Button,
  InputGroup,
  Select,
  HStack,
  Spacer,
  InputLeftElement,
  useToast,
  Center,
} from "@chakra-ui/react";
import { ethers, ContractTransaction } from "ethers";
import { parseEther } from "ethers/lib/utils";
import GradientLinkBtn from "../../../components/GradientLinkBtn";

function LimitOrderSection({
  userAddress,
  daiContract,
  limitOrderContract,
  exchangeContract,
}: {
  userAddress: string;
  daiContract: any;
  limitOrderContract: any;
  exchangeContract: any;
}) {
  const toast = useToast();

  const [nftAmount, setNftAmount] = useState<number>();
  const [targetPricePerNft, setTargetPricePerNft] = useState<number>();
  const [isBtnLoading, setIsBtnLoding] = useState(false);

  const placeLimitOrder = async () => {
    if (!nftAmount || !targetPricePerNft) {
      toast({
        title: "Input can't be 0",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
      return;
    }

    setIsBtnLoding(true);

    try {
      const allowance = await daiContract.allowance(
        userAddress,
        limitOrderContract.address
      );
      if (
        allowance.lt(parseEther((nftAmount! * targetPricePerNft!).toString()))
      ) {
        // allow to spend user's dai
        const approveTxn = await daiContract.approve(
          // aaveHelperContract.address,
          limitOrderContract.address,
          ethers.constants.MaxUint256
        );
        await approveTxn.wait();
      }
      // place order
      const tx = (await limitOrderContract.placeOrder({
        user: userAddress,
        pool: exchangeContract.address,
        ERC1155ToBuy: nftAmount,
        ERC20ToSell: parseEther(targetPricePerNft!.toString()),
      })) as ContractTransaction;

      tx.wait().then((r) => {
        console.log(r);
        setIsBtnLoding(false);
      });
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        status: "error",
        isClosable: true,
        duration: 2000,
      });
      setIsBtnLoding(false);
    }
  };

  return (
    <Box mt="1rem" p="2rem" border="1px solid white" rounded="xl">
      <Heading>Limit Order</Heading>
      <Center flexDir={"column"} mt="2rem">
        <Text>Enter NFT Amount to Buy:</Text>
        <Input
          mt="0.5rem"
          maxW="20rem"
          type="number"
          placeholder="0"
          aria-label="nft-amount"
          autoComplete="off"
          textAlign="right"
          value={nftAmount ? nftAmount : ""}
          onChange={(e) => {
            let amt = parseFloat(e.target.value);
            setNftAmount(amt);
          }}
          isDisabled={!userAddress}
        />
        <Text mt="1rem">Target DAI per NFT:</Text>
        <Input
          mt="0.5rem"
          maxW="20rem"
          type="number"
          placeholder="0"
          aria-label="nft-amount"
          autoComplete="off"
          textAlign="right"
          value={targetPricePerNft ? targetPricePerNft : ""}
          onChange={(e) => {
            let amt = parseFloat(e.target.value);
            setTargetPricePerNft(amt);
          }}
          isDisabled={!userAddress}
        />
        <Text mt="1rem">
          Total DAI to Pay:{" "}
          {(nftAmount ? nftAmount : 0) *
            (targetPricePerNft ? targetPricePerNft : 0)}
        </Text>
        <Box mt="1rem">
          <GradientLinkBtn
            text={userAddress ? "Place Limit Order" : "Connect Wallet ⬈"}
            onClick={() => placeLimitOrder()}
            isLoading={isBtnLoading}
            isDisabled={!userAddress}
          />
        </Box>
      </Center>
    </Box>
  );
}

export default LimitOrderSection;
