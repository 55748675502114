import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  Button,
  InputGroup,
  Select,
  HStack,
  Spacer,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { ethers, ContractTransaction, BigNumber } from "ethers";
import GradientLinkBtn from "../../../components/GradientLinkBtn";
import { formatNumber, toDecimal } from "../../../utils";

function SwapSection({
  userAddress,
  tokenContract,
  daiContract,
  nftContract,
  nftId,
  exchangeContract,
  // aaveHelperContract,
  refreshPoolStats,
}: {
  userAddress: string;
  tokenContract: any;
  daiContract: any;
  nftContract: any;
  nftId: number;
  exchangeContract: any;
  // aaveHelperContract: any;
  refreshPoolStats: any;
}) {
  const toast = useToast();

  // user balances
  const [tokenUserBalance, setTokenUserBalance] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [daiUserBalance, setDaiUserBalance] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [nftUserBalance, setNftUserBalance] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [lpUserBalance, setLpUserBalance] = useState<BigNumber>(
    BigNumber.from(0)
  );
  // states
  const [isBuyingNFT, setIsBuyingNFT] = useState(true);
  const [nftAmount, setNftAmount] = useState<BigNumber>(BigNumber.from(0));
  const [tokenAmount, setTokenAmount] = useState<BigNumber>(BigNumber.from(0));
  const [isBtnLoading, setIsBtnLoding] = useState(false);
  const [pricePerNft, setPricePerNft] = useState<BigNumber>(BigNumber.from(0));

  // fetch user balances
  useEffect(() => {
    if (
      tokenContract &&
      daiContract &&
      nftContract &&
      exchangeContract &&
      // aaveHelperContract &&
      nftId &&
      userAddress
    ) {
      fetchUserBalances();
    }
  }, [
    tokenContract,
    daiContract,
    nftContract,
    exchangeContract,
    // aaveHelperContract,
    nftId,
    userAddress,
  ]);

  // fetch price
  useEffect(() => {
    const delayFetchQuote = setTimeout(async () => {
      if (nftAmount && exchangeContract) {
        fetchPriceQuote();
      }
    }, 1000);

    return () => clearTimeout(delayFetchQuote);
  }, [nftAmount, exchangeContract, isBuyingNFT]);

  // get price per nft
  useEffect(() => {
    setPricePerNft(
      nftAmount.eq("0") ? BigNumber.from("0") : tokenAmount.div(nftAmount)
    );
  }, [tokenAmount]);

  const fetchUserBalances = async () => {
    setTokenUserBalance(await tokenContract.balanceOf(userAddress));
    setDaiUserBalance(await daiContract.balanceOf(userAddress));
    setNftUserBalance(await nftContract.balanceOf(userAddress, nftId));
    setLpUserBalance(await exchangeContract.balanceOf(userAddress));
  };

  const fetchPriceQuote = async () => {
    setIsBtnLoding(true);
    if (isBuyingNFT) {
      setTokenAmount(
        await exchangeContract.getPriceERC20toERC1155Exact(nftAmount)
      );
    } else {
      setTokenAmount(await exchangeContract.getPriceERC1155toERC20(nftAmount));
    }
    setIsBtnLoding(false);
  };

  const refreshAllStats = () => {
    fetchUserBalances();
    refreshPoolStats();
    fetchPriceQuote();
  };

  const swap = async () => {
    if (nftAmount.eq("0")) {
      toast({
        title: "NFT Amount can't be 0",
        status: "error",
        isClosable: true,
        duration: 3000,
      });
      return;
    }

    setIsBtnLoding(true);

    try {
      if (isBuyingNFT) {
        const allowance = await daiContract.allowance(
          userAddress,
          // aaveHelperContract.address
          exchangeContract.address
        );
        if (allowance.lt(tokenAmount)) {
          // allow to spend user's dai
          const approveTxn = await daiContract.approve(
            // aaveHelperContract.address,
            exchangeContract.address,
            ethers.constants.MaxUint256
          );
          await approveTxn.wait();
        }
        // execute swap
        // const tx = (await aaveHelperContract.buyERC1155WithDAI(
        //   exchangeContract.address,
        //   nftAmount,
        //   daiUserBalance,
        //   "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        // )) as ContractTransaction;
        const tx = (await exchangeContract.swapERC20toERC1155Exact(
          daiUserBalance,
          nftAmount,
          ethers.constants.MaxUint256
        )) as ContractTransaction;

        tx.wait().then((r) => {
          console.log(r);
          refreshAllStats();
          setIsBtnLoding(false);
        });
      } else {
        // NFT -> Token
        const isApproved = await nftContract.isApprovedForAll(
          userAddress,
          // aaveHelperContract.address
          exchangeContract.address
        );
        if (!isApproved) {
          // allow to spend user's NFTs
          const approveTxn = await nftContract.setApprovalForAll(
            // aaveHelperContract.address,
            exchangeContract.address,
            true
          );
          await approveTxn.wait();
        }
        // execute swap
        // const tx = (await aaveHelperContract.sellERC1155ToDAI(
        //   exchangeContract.address,
        //   nftAmount,
        //   0,
        //   "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        // )) as ContractTransaction;
        const tx = (await exchangeContract.swapExactERC1155ToERC20(
          nftAmount,
          0,
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        )) as ContractTransaction;

        tx.wait().then((r) => {
          console.log(r);
          refreshAllStats();
          setIsBtnLoding(false);
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        status: "error",
        isClosable: true,
        duration: 2000,
      });
      setIsBtnLoding(false);
    }
  };

  return (
    <Box flex={1} minW="60%" border="1px solid white" rounded="xl">
      <Box px={3} py={8}>
        <Heading pl="1.5rem" size="lg">
          Swap
        </Heading>
        <Box mt="4rem" mx="4rem">
          <VStack>
            <FormControl>
              <FormLabel>
                <HStack>
                  <Text>
                    Enter NFT Amount to {isBuyingNFT ? "Buy" : "Sell"}:
                  </Text>
                  <Spacer />
                  <Text color="gray.300">
                    Balance: {formatNumber(nftUserBalance, 0)}
                  </Text>
                </HStack>
              </FormLabel>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="0"
                  aria-label="nft-amount"
                  autoComplete="off"
                  textAlign="right"
                  value={nftAmount.toString()}
                  onChange={(e) => {
                    let amt = e.target.value;
                    if (!amt) amt = "0";
                    setNftAmount(BigNumber.from(amt));
                  }}
                  isDisabled={!userAddress}
                />
                {!isBuyingNFT && (
                  <InputLeftElement w="4.5rem" mr="0.1rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={() => setNftAmount(nftUserBalance)}
                    >
                      Max
                    </Button>
                  </InputLeftElement>
                )}
              </InputGroup>
            </FormControl>
            <Box>
              <Button
                mt="2rem"
                mb="1rem"
                border="1px solid"
                borderColor="gray.600"
                rounded="xl"
                bg="transparent"
                fontSize="3xl"
                py="8"
                px="5"
                onClick={() => {
                  setIsBuyingNFT(!isBuyingNFT);
                }}
                isDisabled={!userAddress}
              >
                <Text fontWeight="normal">⇅</Text>
              </Button>
            </Box>
            <FormControl>
              <FormLabel>
                <HStack>
                  <Text>{isBuyingNFT ? "Pay" : "Receive"}:</Text>
                  <Spacer />
                  <Text color="gray.300">
                    Balance: {formatNumber(daiUserBalance)}
                  </Text>
                </HStack>
              </FormLabel>
              <InputGroup>
                <Input
                  type="number"
                  placeholder="0"
                  aria-label="erc20-amount"
                  autoComplete="off"
                  textAlign="right"
                  borderRightStyle="none"
                  roundedRight="none"
                  value={toDecimal(tokenAmount, 18)}
                  isReadOnly
                  isDisabled={!userAddress}
                />
                <Select
                  w="30%"
                  _hover={{ cursor: "pointer" }}
                  roundedLeft="none"
                  isDisabled={!userAddress}
                >
                  <option>DAI</option>
                  <option disabled>USDC</option>
                  <option disabled>WETH</option>
                </Select>
              </InputGroup>
            </FormControl>
            <Box w="100%">
              <Box mt="1rem">
                <HStack>
                  <Box>
                    <Text>Price Per NFT:</Text>
                    {/* <Text>Price Impact:</Text> */}
                  </Box>
                  <Spacer />
                  <Box textAlign="right">
                    <Text>{formatNumber(pricePerNft)} DAI</Text>
                    {/* <Text>1%</Text> */}
                  </Box>
                </HStack>
              </Box>
            </Box>
            <Box>
              <Box mt="2rem">
                <GradientLinkBtn
                  text={
                    userAddress
                      ? isBuyingNFT
                        ? "BUY NFTs"
                        : "SELL NFTs"
                      : "Connect Wallet ⬈"
                  }
                  onClick={() => swap()}
                  isLoading={isBtnLoading}
                  isDisabled={!userAddress}
                />
              </Box>
            </Box>
          </VStack>
        </Box>
      </Box>
    </Box>
  );
}

export default SwapSection;
