import { Heading, Box, Text, Center, VStack } from "@chakra-ui/react";

function Card({
  title,
  description,
  img,
}: {
  title: string;
  description: string;
  img: JSX.Element;
}) {
  return (
    <Box
      flex={1}
      maxW="34rem"
      pb="2"
      border="2px"
      borderColor="white"
      rounded="1rem"
    >
      <Center pt={10} px={6}>
        <VStack spacing={8}>
          <Heading as="h2" fontWeight="semibold">
            {title}
          </Heading>
          <Text whiteSpace="pre-wrap" textAlign="center">
            {description}
          </Text>
          {img}
        </VStack>
      </Center>
    </Box>
  );
}

export default Card;
