import {
  Flex,
  Heading,
  HStack,
  Box,
  Select,
  Text,
  Stack,
  Link,
} from "@chakra-ui/react";
import ConnectWallet from "./ConnectWallet";
import Logo from "./Logo";
import { slicedAddress } from "../utils";
import { setProvider } from "../types";

function Navbar({
  chainId,
  setChainId,
  address,
  setProvider,
}: {
  chainId: number;
  setChainId: any;
  address: string;
  setProvider: setProvider;
}) {
  return (
    <Flex
      py="4"
      px={["2", "4", "10", "10"]}
      borderBottom="2px"
      borderBottomColor="gray.400"
    >
      <Heading maxW={["302px", "4xl", "4xl", "4xl"]}>
        <Link href="/">
          <HStack spacing={4}>
            <Logo w="3rem" h="3rem" rounded="lg" /> <Box>NFTPool</Box>
          </HStack>
        </Link>
      </Heading>
      <Flex flex="1" justifyContent="flex-end" alignItems="center">
        <Box mr="1rem">
          <Select
            value={chainId}
            onChange={(e) => {
              setChainId(parseInt(e.target.value));
            }}
            _hover={{ cursor: "pointer" }}
          >
            <option value={80001}>Polygon Testnet</option>
            <option value={42} disabled>
              Kovan Testnet
            </option>
            <option value={5} disabled>
              Goerli Testnet
            </option>
          </Select>
        </Box>
        {address ? (
          <Stack
            bg="gray.700"
            border="1px solid"
            borderColor="gray.500"
            _hover={{
              border: "1px",
              borderStyle: "solid",
              borderColor: "blue.400",
              backgroundColor: "gray.700",
              cursor: "pointer",
            }}
            borderRadius="xl"
            px={3}
            py={2}
          >
            <Text fontWeight="semibold">{slicedAddress(address)}</Text>
          </Stack>
        ) : (
          <ConnectWallet setProvider={setProvider} chainId={chainId} />
        )}
      </Flex>
    </Flex>
  );
}

export default Navbar;
