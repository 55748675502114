import React from "react";
import { Heading } from "@chakra-ui/react";

function MainHeading({ children }: { children: React.ReactNode }) {
  return (
    <Heading
      as="h1"
      fontSize={{ base: "4xl", sm: "4xl", md: "6xl" }}
      lineHeight={"110%"}
      pb="1rem"
      borderBottom="2px"
      borderBottomColor="whiteAlpha.400"
    >
      {children}
    </Heading>
  );
}

export default MainHeading;
