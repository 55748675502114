import { Container, Stack, Box, Text, Center } from "@chakra-ui/react";
import GradientLinkBtn from "../../../components/GradientLinkBtn";
import GradientText from "../../../components/GradientText";
import Logo from "../../../components/Logo";
import MainHeading from "../MainHeading";

function HeaderSection() {
  return (
    <Container maxW="3xl">
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 8, md: 10 }}
        pt={{ base: 20, md: 20 }}
        pb={{ base: 5, md: 30 }}
      >
        <Center>
          <Logo />
        </Center>
        <MainHeading>
          NFT
          <GradientText text="Pool" />
        </MainHeading>
        <Text
          color="white"
          fontSize={{ base: "xl", sm: "2xl", md: "3xl" }}
          fontWeight="bold"
        >
          Instantly Buy & Sell NFTs via AMM pools for any ERC1155 NFT / ERC20
          Pair
        </Text>
        <GradientLinkBtn text="Open App" href="/testnet/swap" />
      </Stack>
    </Container>
  );
}

export default HeaderSection;
