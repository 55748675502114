import { Container, Image, Stack } from "@chakra-ui/react";
import Card from "../Card";

function FeaturesSection() {
  return (
    <Container minW={{ base: "100%", lg: "5xl" }} mt="10" alignItems="center">
      <Stack
        direction={{ base: "column", md: "row" }}
        alignItems={{ base: "center", md: "stretch" }}
        mx={4}
        spacing={5}
        justifyContent="space-between"
      >
        <Card
          title="Instant Swaps"
          description="No order books here! Execute one-click trades with the same
            experience of using AMMs like Uniswap"
          img={<Image draggable="false" src="swaps-illustration.png" />}
        />
        <Card
          title="Add Liquidity"
          description={`Put your NFTs in liquidity pool and earn LP fees with each trade!\nLP tokens also open up new farming opportunities 👀`}
          img={
            <Image pl="6" draggable="false" src="liquidity-illustration.png" />
          }
        />
      </Stack>
    </Container>
  );
}

export default FeaturesSection;
