import { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { Web3Provider, Signer } from "../../types";
import Body from "./Body";

function Swap() {
  const [provider, setProvider] = useState<Web3Provider>();
  const [signer, setSigner] = useState<Signer>();
  const [address, setAddress] = useState<string>("");
  const [chainId, setChainId] = useState(80001);

  useEffect(() => {
    if (provider) {
      setSigner(provider.getSigner(0));
    }
  }, [provider]);

  useEffect(() => {
    const getAddress = async () => {
      if (signer) {
        setAddress(await signer.getAddress());
      }
    };

    getAddress();
  }, [signer]);

  return (
    <div>
      <Navbar
        chainId={chainId}
        setChainId={setChainId}
        address={address}
        setProvider={setProvider}
      />
      <Body
        address={address}
        provider={provider}
        signer={signer}
        chainId={chainId}
      />
      <Footer />
    </div>
  );
}

export default Swap;
