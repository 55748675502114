import { useState, useEffect } from "react";
import { Box, Container, HStack } from "@chakra-ui/react";
import NFTInfoSection from "./sections/NFTInfoSection";
import SwapSection from "./sections/SwapSection";
import { Web3Provider, Signer } from "../../types";
import { ethers, Contract } from "ethers";
import LimitOrderSection from "./sections/LimitOrderSection";

// abi
const ERC20ABI = require("../../abi/ERC20.json");
const ERC1155ABI = require("../../abi/ERC1155.json");
const ExchangeABI = require("../../abi/Exchange.json");
const LimitOrderABI = require("../../abi/LimitOrder.json");
// const AaveHelperABI = require("../../abi/AaveHelper.json");

const chainIdToAddress: {
  [id: number]: {
    [name: string]: string;
  };
} = {
  42: {
    tokenAddress: "0xB7ED788188bF83d44c7CA9e48E12216991a5045b", // DAI
    daiAddress: "0xB7ED788188bF83d44c7CA9e48E12216991a5045b",
    nftAddress: "0x81fD3646f5b422C92Ec39Cfb1359df40E78624Da",
    exchangeAddress: "0x68cBb2802296856D44694668Ac281f18C86CaB6e",
    limitOrderAddress: "0xF0C686bd8fd842247160A5567eeF2ED9F2F9db8b",
  },
  80001: {
    tokenAddress: "0xc76dCEB05d91E13FB8756CB9D01AB62ecdEE3C1C", // DAI
    daiAddress: "0xc76dCEB05d91E13FB8756CB9D01AB62ecdEE3C1C",
    nftAddress: "0x0c71607598529B3f3bd4Fe10227148D22b4301fA",
    exchangeAddress: "0x78a50Dbf7C7277B9de96AA4E480bCeE9C5Ef9520",
    limitOrderAddress: "0xC9d7149D125a0c0EF0C9fE1cB42Bfea3ecC1da1c",
  },
};

// address

// const aaveHelperAddress = "0xAbD31a8F11F3fBa11B8Bc364da9a73b922BB2d22";

const nftId = 10;

function Body({
  address,
  provider,
  signer,
  chainId,
}: {
  address: string;
  provider: Web3Provider;
  signer?: Signer;
  chainId: number;
}) {
  // contracts
  const [tokenContract, setTokenContract] = useState<Contract>();
  const [daiContract, setDaiContract] = useState<Contract>();
  const [nftContract, setNftContract] = useState<Contract>();
  const [exchangeContract, setExchangeContract] = useState<Contract>();
  const [limitOrderContract, setLimitOrderContract] = useState<Contract>();
  // const [aaveHelperContract, setAaveHelperContract] = useState<Contract>();
  // state
  const [tempBool, setTempBool] = useState(false);

  const addresses = chainIdToAddress[chainId];

  const refreshPoolStats = () => {
    setTempBool(!tempBool);
  };

  useEffect(() => {
    if (provider && signer) {
      setTokenContract(
        new ethers.Contract(addresses.tokenAddress, ERC20ABI, signer)
      );
      setDaiContract(
        new ethers.Contract(addresses.daiAddress, ERC20ABI, signer)
      );
      setNftContract(
        new ethers.Contract(addresses.nftAddress, ERC1155ABI, signer)
      );
      setExchangeContract(
        new ethers.Contract(addresses.exchangeAddress, ExchangeABI, signer)
      );
      setLimitOrderContract(
        new ethers.Contract(addresses.limitOrderAddress, LimitOrderABI, signer)
      );
      // setAaveHelperContract(
      //   new ethers.Contract(aaveHelperAddress, AaveHelperABI, signer)
      // );
    }
  }, [provider, signer]);

  return (
    <Box mt="4rem" mb="4rem" mx="5rem">
      <Container minW="80%">
        <HStack justifyContent="space-between" alignItems="stretch">
          <SwapSection
            userAddress={address}
            tokenContract={tokenContract}
            daiContract={daiContract}
            nftContract={nftContract}
            nftId={nftId}
            exchangeContract={exchangeContract}
            // aaveHelperContract={aaveHelperContract}
            refreshPoolStats={refreshPoolStats}
          />
          <NFTInfoSection
            nftAddress={addresses.nftAddress}
            nftId={nftId}
            exchangeContract={exchangeContract}
            tempBool={tempBool}
          />
        </HStack>
        <LimitOrderSection
          userAddress={address}
          daiContract={daiContract}
          limitOrderContract={limitOrderContract}
          exchangeContract={exchangeContract}
        />
      </Container>
    </Box>
  );
}

export default Body;
