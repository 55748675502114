import { Text } from "@chakra-ui/react";

function GradientText({ text }: { text: string }) {
  return (
    <Text as={"span"} bgGradient="linear(to-l, #9600f0,#c900c4)" bgClip="text">
      {text}
    </Text>
  );
}

export default GradientText;
