import { useState, useEffect } from "react";
import { BigNumber } from "@ethersproject/bignumber";
import {
  Box,
  Center,
  HStack,
  Image,
  Text,
  Spacer,
  Link,
  useToast,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import GradientLinkBtn from "../../../components/GradientLinkBtn";
import { slicedAddress, formatNumber } from "../../../utils";

function NFTInfoSection({
  nftAddress,
  nftId,
  exchangeContract,
  tempBool,
}: {
  nftAddress: string;
  nftId: number;
  exchangeContract: any;
  tempBool: boolean;
}) {
  const toast = useToast();

  const [tokenReserve, setTokenReserve] = useState<BigNumber>(
    BigNumber.from(0)
  );
  const [nftReserve, setNftReserve] = useState<BigNumber>(BigNumber.from(0));

  useEffect(() => {
    if (exchangeContract) {
      exchangeContract
        .getReserves()
        .then(
          ({
            ERC20Reserve,
            ERC1155Reserve,
          }: {
            ERC20Reserve: BigNumber;
            ERC1155Reserve: BigNumber;
          }) => {
            setTokenReserve(ERC20Reserve);
            setNftReserve(ERC1155Reserve);
          }
        );
    }
  }, [exchangeContract, tempBool]);

  return (
    <Box flex={1} w="40%" border="1px solid white" rounded="xl">
      <Box w="100%" px={8} py={8}>
        <Center>
          <Image
            src="https://testnet.nftpool.exchange/kovan/sword.png"
            rounded="lg"
            maxH="20rem"
          />
        </Center>
        <Box mt="1rem" px="1rem">
          <HStack>
            <Box fontWeight="semibold">
              <Text>Name:</Text>
              <Text>Address:</Text>
              <Text>NFT ID:</Text>
            </Box>
            <Spacer />
            <Box textAlign="right">
              <Text>CryptoSwords</Text>
              <Link
                href={`https://kovan.etherscan.io/address/${nftAddress}`}
                target="_blank"
              >
                <HStack>
                  <Text>{slicedAddress(nftAddress)}</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Link>
              <Text>{nftId}</Text>
            </Box>
          </HStack>
        </Box>
        <Box
          mt="1rem"
          px="1rem"
          borderTop="1px solid white"
          rounded="lg"
          pt="1rem"
        >
          <Text
            fontWeight="bold"
            fontSize="lg"
            textDecor="underline"
            textAlign="center"
          >
            Pool Info
          </Text>
          <HStack>
            <Box>
              <Text>NFT Reserves:</Text>
              <Text>DAI Reserves:</Text>
            </Box>
            <Spacer />
            <Box textAlign="right">
              <Text>{formatNumber(nftReserve, 0)}</Text>
              <Text>{formatNumber(tokenReserve)}</Text>
            </Box>
          </HStack>
          <Box mt="1rem">
            <Center>
              <GradientLinkBtn
                py="6"
                text={
                  <>
                    Add Liquidity <ExternalLinkIcon ml="0.5rem" />
                  </>
                }
                onClick={() => {
                  toast({
                    title: "UI Coming soon",
                    status: "error",
                    isClosable: true,
                    duration: 2500,
                  });
                }}
              />
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default NFTInfoSection;
